import * as React from "react"
import { Row, Col, Button, Card, Alert, Modal } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import usePagination from "./pagination"

const VideosComponent = ({ data }) => {
  const PER_PAGE = 12
  const pageCount = Math.ceil(data.length / PER_PAGE)
  const Paginate = usePagination(data, PER_PAGE)

  const [modalShow, setModalShow] = React.useState(false)
  const [modalContent, setModalContent] = React.useState({})

  const updateModal = (state, data) => {
    setModalShow(state)
    setModalContent(data)
  }

  return (
    <React.Fragment>
      {Paginate.currentData().length === 0 && (
        <Row>
          <Col>
            <Alert variant="primary" className="w-100">
              Nenhum vídeo encontrado.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        {Paginate.currentData().map((video, index) => {
          return (
            <React.Fragment key={index}>
              {video.link && (
                <Col
                  md={4}
                  className="article article-card mt-2 mb-4"
                  data-sal="fade"
                  data-sal-duration="500"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  <div
                    onClick={() => updateModal(true, video)}
                    className="mouse-pointer"
                  >
                    <Card className="h-100">
                      {/* <img
                        src={`https://img.youtube.com/vi/${video.link}/mqdefault.jpg`}
                        style={{ width: "100%" }}
                        alt="YouTube Thumbnail"
                      /> */}
                      <div
                        style={{
                          height: 180,
                          width: "100%",
                          backgroundImage: `url('https://img.youtube.com/vi/${video.link}/mqdefault.jpg')`,
                          backgroundSize: "cover",
                          backgroundPosition: "50% 50%"
                        }}
                      />
                      <Card.Body className="bg-light-grey py-3">
                        <Row>
                          <Col>
                            <h5 className="mb-1">{video.title}</h5>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col className="pr-0">
                            <p className="mb-0 text-sm">{video.description}</p>
                          </Col>
                          <Col md={3} xs={3} className="">
                            <StaticImage
                              src="../images/industrial.png"
                              className="pull-right"
                              alt=""
                              placeholder="tracedSVG"
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )}
            </React.Fragment>
          )
        })}
      </Row>
      {data.length > PER_PAGE && (
        <Row className="mt-4">
          <Col className="text-left">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.prev()
              }}
              disabled={Paginate.currentPage === 1 ? true : false}
            >
              Anterior
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.next()
              }}
              disabled={Paginate.currentPage === pageCount ? true : false}
            >
              Próxima
            </Button>
          </Col>
        </Row>
      )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="centered-modal"
        centered
        className="videos-modal"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <Row>
            <Col>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${modalContent.link}`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                  className="embed-responsive-item"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default VideosComponent
