import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Videos from "../components/videos"
import Form from "../components/form"

const ContentPage = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        videos(sort: "created_at:desc") {
          id
          created_at
          title
          link
          description
          category {
            title
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Vídeos" />
      <Container className="padding-top padding-bottom">
        <Row className="title padding-bottom text-center justify-content-center">
          <Col md={10}>
            <h1 className="mb-1">Conheça nossos cases de sucesso</h1>
            <p>
              Entenda por que a energia fotovoltaica é a solução para você, sua
              empresa ou propriedade rural.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={12}>
            <Videos data={data.api.videos} />
          </Col>
        </Row>
      </Container>
      <div className="bg-light-grey">
        <Container className="padding-top padding-bottom">
          <Row className="title half-padding-bottom text-center justify-content-center">
            <Col md={10}>
              <h1>Quero mais informações</h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10}>
              <Form />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ContentPage
